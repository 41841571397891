.button-secondary {
    text-decoration: none;
    display: inline-block;
    padding: 7px 3px;
    color: rgb(var(--color-primary));
    border-bottom: 1px solid rgb(var(--color-primary));
    transition: all .2s;

    &:hover {
        background-color: rgb(var(--color-primary));
        color: rgb(var(--color-white));;
        transform: translateY(-3px);
        box-shadow: 0 5px 10px rgba(var(--color-black), 0.3);
    }

}