.section-details-container {
    padding: 25rem 0;
    background-color: rgb(var(--color-grey-light-2));
    margin-top: -20vh;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__content {
        margin-top: 8rem;
    }
}