.header-container {
    height: 95vh;
    background-image: linear-gradient(
            to right bottom,
            rgba(var(--color-primary-light), 0.8),
            rgba(var(--color-primary-dark), 0.8)
            ),
            url('../../assets/img/hero.jpg');
    background-size: cover;
    background-position: top;
    clip-path: polygon(0 0, 100% 0, 100% 75vh, 0 100%);
    overflow: hidden;    
    position: relative;
    

    .logo {
        margin: 4rem;
        height: 3.5rem;
    }
    

    &__content {
        position: absolute;

        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-transform: uppercase;
        color: rgb(var(--color-white));
        text-align: center;


        .heading-primary {
            display: flex;
            flex-direction: column;
            margin-bottom: 6rem;
            &--main {
                font-size: 6rem;
                font-weight: 400;
                letter-spacing: 3.5rem;
                margin-bottom: 2rem;
                animation-name: text-main-animation;
                animation-duration: 1s;
                animation-timing-function: ease-out;

                @media only screen and (max-width: 600px) {
                    font-size: 4rem;
                    letter-spacing: 1.5rem;
                }
            }
            &--sub {
                letter-spacing: 1.5rem;
                font-size: 2rem;
                font-weight: 600;
                animation-name: text-sub-animation;
                animation-duration: 1s;
                animation-timing-function: ease-out;
            }
        }
    }
}

@keyframes text-main-animation {
    0% {
         transform: translateX(-100px);
         opacity: 0;
        
    }
    80% {
        transform: translateX(10px);
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes text-sub-animation {
    0% {
         transform: translateX(100px);
         opacity: 0;
        
    }
    80% {
        transform: translateX(-10px);
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}