.form-with-image-container {
    width: 100%;
    
    padding: 6rem;
    background-image: linear-gradient(
        105deg,
        rgba(var(--color-white), .85) 0%,
        rgba(var(--color-white), .85) 50%,
        transparent 50%,
    ),
    url('../../assets/img/nat-10.jpg');
    background-size: cover;

    @media only screen and (max-width: 1200px) {
        background-image: linear-gradient(
        105deg,
        rgba(var(--color-white), .85) 0%,
        rgba(var(--color-white), .85) 65%,
        transparent 65%,
    ),
    url('../../assets/img/nat-10.jpg');
    }

    @media only screen and (max-width: 900px) {
        background-image: linear-gradient(
        105deg,
        rgba(var(--color-white), .85) 0%,
        rgba(var(--color-white), .85) 100%
    ),
    url('../../assets/img/nat-10.jpg');
    }

    .booking-form {
        width: 40%;
        margin-top: 4rem;

        @media only screen and (max-width: 900px) {
            width: 100%;
        }

        button {
            width: 100%;
        }

        .radio-inputs-container {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: 4rem;

            & > * {
                margin-bottom: 2rem;
                min-width: 40%;
            }
        }

    }
}