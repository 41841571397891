.button-primary {
    display: inline-block;
    padding: 2rem 4rem;
    text-transform: uppercase;
    border-radius: 100px;
    border: none;
    font-size: 1.7rem;
    text-decoration: none;
    position: relative;
    transition: all .3s;
    cursor: pointer;

    &.base {
        background-color: rgb(var(--color-white));
        color: rgb(var(--color-grey-dark));
    }

    &.green {
        background-color: rgb(var(--color-primary));
        color: rgb(var(--color-white));
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: inherit;
        border-radius: 100px;
        z-index: -1;
        transition: all .3s ease-out;
    }

    &:hover {
        transform: translateY(-3px);
        box-shadow: 0 10px 20px rgba(0,0,0, .2);
        &::before {
            transform: scale(1.4);
            opacity: 0;
        }
    }

    &:active {
        transform: translateY(-1px);
        box-shadow: 0 5px 10px rgba(0,0,0, .2);
    }
}