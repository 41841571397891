.card-booking-back-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    overflow: hidden;

    @media only screen and (max-width: 900px) {
        height: 225px;
        clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 100%);
    }
    

    .booking-details {

        .price-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 8rem;
            text-transform: uppercase;
            color: rgb(var(--color-white));

            @media only screen and (max-width: 900px) {
                margin-bottom: 3rem;
            }
            
            span {
                margin-bottom: 2rem;
                font-size: 1.5rem;

                @media only screen and (max-width: 900px) {
                    margin-bottom: 1rem;
                }
            }
    
            .price {
                font-size: 6rem;
                font-weight: lighter;
                @media only screen and (max-width: 900px) {
                    font-size: 4rem;
                }
            }
        }

    }

    &.green {
        background-image: linear-gradient(
            to right bottom,
            rgb(var(--color-primary-light)),
            rgb(var(--color-primary-dark)),
        );
    }
    &.yellow {
        background-image: linear-gradient(
            to right bottom,
            rgb(var(--color-secondary-light)),
            rgb(var(--color-secondary-dark)),
        );
    }
    &.blue {
        background-image: linear-gradient(
            to right bottom,
            rgb(var(--color-tertiary-light)),
            rgb(var(--color-tertiary-dark)),
        );
    }
}