.navigation-button {
    z-index: 9999;
    position: fixed;
    top: 4rem;
    right: 4rem;
    height: 7rem;
    width: 7rem;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 1rem 3rem rgba($color: #000000, $alpha: .1);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .strings {
        width: 3rem;
        position: relative;
        div {
            transition: all .2s;
            border-radius: 1px;
            height: .2rem;
            width: 100%;
            background-color: black;
            &:not(:last-child) {
                margin-bottom: .6rem;
            }
        }
    }

    .strings-clicked {
        div {
            &:nth-child(2) {
                display: none;
            }
            &:nth-child(1) {
                position: absolute;
                top: 50%;
                transform: translateY(-50%) rotate(315deg);
            }
            &:nth-child(3) {
                position: absolute;
                top: 50%;
                transform: translateY(-50%) rotate(405deg);
            }
        }
    }
    
}