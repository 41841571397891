.radio-group {
    display: inline-block;    

    &__input {
        display: none;
    }

    &__label {
        cursor: pointer;
        text-transform: capitalize;
        display: flex;
        align-items: center;

        .radio-button {
            height: 3rem;
            width: 3rem;
            border-radius: 50%;
            position: relative;
            border: .5rem rgb(var(--color-primary)) solid;
            margin-right: 1rem;

            span {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                height: 1.2rem;
                width: 1.2rem;
                border-radius: 50%;    
            }

            .radio-button-checked {
                background-color: rgb(var(--color-primary));
            }
        }
    }
}