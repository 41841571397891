.navigation-background {
    position: fixed;
    top: 4.5rem;
    right: 4.5rem;
    height: 6rem;
    width: 6rem;
    background-image: radial-gradient(rgb(var(--color-primary-light)), rgb(var(--color-primary)));
    border-radius: 50%;
    z-index: 1000;
    transition: transform 0.8s
                cubic-bezier(0.86, 0, 0.07, 1),
                -webkit-transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);

    &.scaleup {
        transform: scale(80);
    }
}

