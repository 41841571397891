.card-booking-container {
    position: relative;
    perspective: 150rem;
    
    
    

    .back, .front {
        transition: .7s all;
        backface-visibility: hidden;
        box-shadow: 0 10px 20px rgba($color: #000000, $alpha: .1);
    }

    .back {
        position: absolute;
        top: 0;
        left: 0;
        transform: rotateY(180deg);
    }

    &:hover {
        
        & .back {
            transform: rotateY(0);
        }

        & .front {
            transform: rotateY(-180deg);
        }
    }

    @media only screen and (max-width: 900px) {
        background-color: #fff;
        box-shadow: 0 10px 20px rgba($color: #000000, $alpha: .1);

        .front, .back {
            box-shadow: none;
        }

        .back {
            transform: rotateY(0);
            position: relative;
        }

        &:hover {
            & .front {
                transform: rotateY(0);
            }
        }
    }
}