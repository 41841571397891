.review-container {
    max-width: 75%;
    margin: 0 auto;
    margin-bottom: 6rem;
    padding: 5rem;
    background-color: #fff;
    box-shadow: 0 5px 10px rgba($color: #000000, $alpha: 0.2);
    transform: skewX(-12deg);

    @media only screen and (max-width: 900px) {
        transform: skewX(0);
        padding: 3rem;
    }

    @media only screen and (max-width: 600px) {
        max-width: 92%;
    }

    &__content {
        transform: skewX(12deg);
        display: flex;

        @media only screen and (max-width: 900px) {
            transform: skewX(0);
        }

        @media only screen and (max-width: 600px) {
            flex-direction: column;
            align-items: center;

            h3 {
                text-align: center;
            }
            p {
                text-align: center;
            }
        }

        .image-container {
            height: 15rem;
            width: 20rem;
            margin-right: 4rem;
            overflow: hidden;
            border-radius: 50%;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            @media only screen and (max-width: 600px) {
                margin-right: 0;
                margin-bottom: 2.5rem;
                height: 15rem;
                width: 15rem;
            }

            .name {
                position: absolute;
                display: flex;
                flex-direction: column;
                text-align: center;
                transform: translateY(4rem);
                opacity: 0;
                transition: .5s all;
                cursor: default;

                span {
                    text-transform: uppercase;
                    color: #fff;
                    font-weight: 400;
                    letter-spacing: 1px;
                    font-size: 1.6rem;

                    &:not(:last-child) {
                        margin-bottom: 1rem;
                    }
                }
            }
    
            img {
                width: 100%;
                height: 100%;
                transform: scale(1.5);    
                transition: .5s all;
                object-fit: cover;
            }
        }
    }
    
    &:hover {
        img {
            transform: scale(1.05);
            filter: blur(3px) brightness(80%);
        }
        
        .name {
            transform: translateY(0);
            opacity: 1;
        }
    }   
    
}