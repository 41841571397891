.card-booking-front-container {
    position: relative;
    border-radius: 3px;
    overflow: hidden;
    

    .background-image {
        height: 23rem;
        background-size: cover;
        background-position: top;
        clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
        background-blend-mode: screen;

        &.green {
            background-image: linear-gradient(
            to right,
            rgb(var(--color-primary-light)),
            rgb(var(--color-primary-dark)),
            ),
            url('../../assets/img/nat-6.jpg');
        }

        &.yellow {
            background-image: linear-gradient(
            to right,
            rgb(var(--color-secondary-light)),
            rgb(var(--color-secondary-dark)),
            ),
            url('../../assets/img/nat-5.jpg');
        }

        &.blue {
            background-image: linear-gradient(
            to right,
            rgb(var(--color-tertiary-light)),
            rgb(var(--color-tertiary-dark)),
            ),
            url('../../assets/img/nat-7.jpg');
        }
    }

    .card-heading {
        width: 75%;
        position: absolute;
        top: 12rem;
        right: 2rem;
        font-size: 2.8rem;
        font-weight: 300;
        text-transform: uppercase;
        color: rgb(var(--color-white));
        text-align: right;
        line-height: 1.75;
        
        
        

        span {
            -webkit-box-decoration-break: clone;   
            padding: 1rem 1.5rem;
        }

        &.green {
            span {
                background-image: linear-gradient(
                rgba(var(--color-primary-light), .8),
                rgba(var(--color-primary-dark), .8)
            );
            }
        }
        &.yellow {
            span {
                background-image: linear-gradient(
                rgba(var(--color-secondary-light), .8),
                rgba(var(--color-secondary-dark), .8)
            );
            }
        }
        &.blue {
            span {
                background-image: linear-gradient(
                rgba(var(--color-tertiary-light), .8),
                rgba(var(--color-tertiary-dark), .8)
            );
            }
        }
    }

    .list {
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 2rem 0;
        margin-top: 1rem;
        li {
            color: rgb(var(--color-grey-dark));
            padding: 1.4rem 0;
            width: 70%;
            
            &:not(:last-child) {
                border-bottom: 1px solid rgb(var(--color-grey-light-2));
            }
            
        }
    }
}
