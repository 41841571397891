:root {
--color-primary: 85, 197, 122;
--color-primary-light: 126, 213, 111;
--color-primary-dark: 40, 180, 133;

--color-secondary-light: 255, 185, 0;
--color-secondary-dark: 255, 119, 48;

--color-tertiary-light: 41, 152, 255;
--color-tertiary-dark: 86, 67, 250;

--color-grey-light-1: 247, 247, 247;
--color-grey-light-2: 238, 238, 238;


--color-grey-dark: 119, 119, 119;
--color-grey-dark-2: 153, 153, 153;
--color-grey-dark-3: 51, 51, 51;


--color-white: 255, 255, 255;
--color-black: 0, 0, 0;


// FONT

--default-font-size: 1.6rem;

// Grid
--grid-width: 118rem;

@media only screen and (max-width: 900px) {
  --grid-width: 50rem;
}

}

html {
  font-size: 62.5%;

  @media only screen and (max-width: 1200px) {
    font-size: 56.25%;
  }

  @media only screen and (max-width: 900px) {
    font-size: 50%;
  }
}

body {
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--default-font-size);
  font-weight: 400;
  padding: 30px;
  color: rgb(var(--color-grey-dark));

  @media only screen and (max-width: 1200px) {
    padding: 0;
  }
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}