.flex-container {
    display: flex;
    max-width: var(--grid-width);
    padding: 0 2rem;
    margin: 0 auto;
    &:not(:last-child) {
        margin-bottom: 8rem;
    }

    @media only screen and (max-width: 900px) {
        padding: 0 3rem;
        flex-direction: column;

    }

    & > * {
        flex: 1;

        &:not(:last-child) {
            margin-right: 6rem;
        }

        @media only screen and (max-width: 1200px) {
            &:not(:last-child) {
                margin-right: 4rem;
            }
        }

        @media only screen and (max-width: 900px) {
            &:not(:last-child) {
                margin-right: 0;
                margin-bottom: 5rem;
            }
        }
    }
}