.footer {
    background-color: rgb(var(--color-grey-dark-3));
    padding: 10rem 0;
    font-size: 1.5rem;
    color: rgb(var(--color-grey-light-1));

    @media only screen and (max-width: 900px) {
        padding: 8rem 0;
    }


    &__logo-box {
        text-align: center;
        margin-bottom: 8rem;

        @media only screen and (max-width: 900px) {
            margin-bottom: 6rem;
        }
    }

    &__logo {
        width: 15rem;
        height: auto;
    }

    &__content {
        display: flex;
        justify-content: space-between;

        @media only screen and (max-width: 900px) {
            flex-direction: column;
            justify-content: center;
        }
    }

    &__navigation {
        border-top: 1px solid rgb(var(--color-grey-dark));
        padding-top: 2rem;
        max-width: fit-content;

        @media only screen and (max-width: 900px) {
            max-width: 100%;
            width: 100%;
            text-align: center;
            margin-bottom: 6rem;
        }
    }

    &__list {
        list-style: none;
    }

    &__item {
        display: inline-block;

        &:not(:last-child) {
            margin-right: 1.5rem;
        }
    }

    &__link {
        &:link,
        &:visited {
            color: rgb(var(--color-grey-light-1));
            background-color: rgb(var(--color-grey-dark-3));
            text-decoration: none;
            text-transform: uppercase;
            display: inline-block;
            transition: all .2s;
        }

        &:hover,
        &:active {
            color: rgb(var(--color-primary));
            box-shadow: 0 1rem 2rem rgba(var(--color-black), .4);
            transform: rotate(5deg) scale(1.3);
        }
    }

    &__copyright {
        border-top: 1px solid rgb(var(--color-grey-dark));
        padding-top: 2rem;
        line-height: 1.6;
        width: 40%;


        @media only screen and (max-width: 900px) {
            width: 100%;
            float: none;
        }
    }
}