.card-features-container {
    background-color: rgb(var(--color-white));
    display: flex;
    height: 30rem;
    padding: 0 2rem;
    border-radius: 3px;
    box-shadow: 0 10px 20px rgba(var(--color-black), .2);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all .2s;
    opacity: .8;

    &:hover {
        transform: 
            translateY(-15px)
            scale(1.02);
        box-shadow:
            0 15px 10px rgba(var(--color-black), .2);
    }

    @media only screen and (max-width: 900px) {
        height: 22.5rem;
    }

    h3 {
        text-align: center;
    }

    &__icon {
        font-size: 6rem;
        color: rgb(var(--color-primary));
        margin-bottom: 2rem;
    }

    &__text {
        text-align: center;
        color: rgb(var(--color-grey-dark));
        line-height: 1.5;
        
    }
}