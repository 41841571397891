.section-booking-container {
    background-color: rgb(var(--color-grey-light-1));
    padding: 20rem 0;
    margin-top: -8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    &__content {
        width: 100%;
        margin: 8rem 0;
    }
}