.popup {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    backdrop-filter: blur(10px);
    background-color: rgba($color: #000000, $alpha: 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;

    &__content {
        border-radius: 5px;
        width: 75%;
        background-color: #fff;
        display: flex;
        position: relative;
        overflow: hidden;
        animation: popupAnimation .5s ease-in;

        @media only screen and (max-width: 900px) {
            width: 92%;
            flex-direction: column;
        }

        @keyframes popupAnimation {
            0% {
                opacity: 0;
                transform: scale(0.25);
            }

            100% {
                opacity: 1;
                transform: scale(1);
            }
        }
    }

    &__close {
        position: absolute;
        top: 2rem;
        right: 2rem;
        text-decoration: none;
        font-size: 3rem;
        color: rgb(var(--color-grey-dark));
        cursor: pointer;

        &:hover {
            color: rgb(var(--color-primary));
        }
    }

    &__left {
        width: 33.333333%;
        margin-right: 5rem;
        display: flex;
        flex-direction: column;

        @media only screen and (max-width: 900px) {
            width: 100%;
            flex-direction: row;
            display: none;
        }
    }

    &__img {
        flex: 1;
        width: 100%;
        object-fit: cover;
        display: block;

        @media only screen and (max-width: 900px) {
            width: 50%;
        }
    }

    &__right {
        padding: 3.5rem 0;
        flex: 1;

        @media only screen and (max-width: 900px) {
            padding: 3.5rem 2rem;
        }

        h2 {
            margin-bottom: 2.5rem;
        }
    }

    &__text {
        font-size: 1.5rem;
        line-height: 1.6;
        margin-bottom: 4rem;
    }
}